import Link from "next/link";
import { memo } from "react";
import { useRouter } from "next/router";
import FacebookIcon from "public/assets/icons/social-icons-2/facebook.svg";
import LinkedinIcon from "public/assets/icons/social-icons-2/linkedin.svg";
import Instagram from "public/assets/icons/social-icons-2/instagram.svg";
import Locales from "./locales/data"


const SocialIcons = ({ isTitle = true }) => {
  const socialNetworks = {
    facebook: {
      link: 'https://www.facebook.com/epr.com.ua',
      color: "hover:text-[#3b5998]",
      icon: <FacebookIcon />
    },
    instagram: {
      link: 'https://www.instagram.com/epr.com.ua',
      color: "hover:text-red-500",
      icon: <Instagram />
    },
    linkedin: {
      link: "https://www.linkedin.com/company/electropromremont-ltd/",
      color: "hover:text-[#0e76a8]",
      icon: <LinkedinIcon />
    },
  };
  const { locale } = useRouter();

  const icons = Object.entries(socialNetworks).map(([network, { link, color, icon }]) => (
      <Link href={link} key={network}>
        <a
          className={`flex justify-center items-center w-10 h-10 min-w-[2.5rem] mx-2 
          border border-white border-opacity-20 rounded-full text-white hover:bg-white
          transition-colors delay-100 ease-in-out ${color}`}
          rel="noopener noreferrer"
          target="_blank"
          aria-label={`Link to ${network} page`}
        >
          {icon}
        </a>
      </Link>
    ))

  return (
    <div className="w-full xl:w-[270px]">
      {isTitle && (
        <p className="text-center uppercase opacity-50 tracking-wider pt-4 lg:pt-0">{Locales[locale]}</p>
      )}
      <div className="flex justify-center w-full my-4">
        {icons}
      </div>
    </div>
  )
};

export default memo(SocialIcons);